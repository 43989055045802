@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
a li {
  list-style-type: none;
}
a {
  cursor: pointer;
}

.btn {
  @apply px-5 py-2 rounded-xl border-4 font-medium inline-block text-tinybase md:text-base;
}

.btn-primary {
  @apply bg-primary text-white;
}

.btn-secondary {
  @apply inline-block border border-primary text-black bg-white font-bold text-[16px];
}

.btn-secondary:hover {
  @apply hover:bg-primary hover:text-white;
}
